<template>
  <LoadingOverlay :show="beforeLoad"/>
  <whiteOverlay :show="beforeLoad"/>
  <main class="main_v8 library">
    <breadcrumb></breadcrumb>

    <h3 class="page_header" :style="[edit  ? { paddingLeft: paddingLeft, paddingRight: paddingRight } : null]">
      <div class="delete_this_page" @click="deleteCurrent = true" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('delete'))"></div>
      {{ this.title }}
      <div class="edit_header_button" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" @click="titleEdit = !titleEdit; editedToSave = 'header'"></div>
      <div class="qr_code_button" v-if="!edit && beforeLoad" @click="qrModalActive = true"><span>Generate QR Code</span></div>
    </h3>

    <p class="page_subheader" @click="here" :style="[edit ? { paddingLeft: paddingLeft, paddingRight: paddingRight } : null]"
     v-if="this.subtitle !== '' && this.subtitle !== null && this.subtitle !== undefined && this.subtitle !== 'null'">{{ this.subtitle }}</p>

    <!-- IMAGE BAR SECTION -->

    <div class="slider_side" :style="[mobilePortrait ? {width: '100%'} : {  }, {}]">
      
      <carousel :items-to-show="1" @slide-start="slideToSlide">
        <slide v-for="(slide, index) in libraryimages" :key="index" :data-index="index">

          <transition name="picture-edit">
            <div v-if="edit && slide != '' && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" class="picture_edit" @click="imgEdit = index; editedToSave = 'pictures'"></div>
          </transition>

          <div v-if="slide != ''" :src='slide' :style="{backgroundImage: `url(${slide})`, height: '100%', width: '100%', backgroundSize: '100%' }" @click.stop="show(index)">
            <span :style="[edit ? {'right': positionRight} : { 'right': '25px' }]">{{ index+1 + ' of 4' }}</span>
          </div>

          <div v-if="slide == '' && !edit" class="emptyimage">
            <p>No Image</p>
          </div>
          <transition v-if="(this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit')">
            <div v-if="slide == '' && edit" class="upload" @click="imgEdit = index; editedToSave = 'pictures'">
              <!-- <DropZoneImage :height="50" :index="index" :docid="file_one" @drop.prevent="drop"
                @change="selectedFile" @uploadStarted="uploadStarted" @uploadCompleted="uploadComplete" /> -->
                <div class="dropzone"></div>
            </div>
          </transition>
          <transition v-else>
            <div v-if="slide == '' && edit" class="upload">
                <div class="dropzone"></div>
            </div>
          </transition>
        </slide>
        <template #addons>
          <Navigation v-if="beforeLoad"/>
        </template>
      </carousel>
    
      <div class="pag" v-if="beforeLoad">
        <span v-for="indexx in 4" :key="indexx" :style="[[isActive == indexx - 1 ? {backgroundColor: 'rgb(188,188,188)', width: pagActiveWidth } : null], [isActive == !indexx-1 ? {width: pagWidth} : null]]"></span>
      </div>
      <div class="show_image" v-if="imageToShow != ''" @click="imageToShow = ''">
        <v-zoomer>
          <img :src="imageToShow" style="height: 100%; max-width: 100%; object-fit: contain; transform: initial;" @click.stop />
        </v-zoomer>
      </div>
    </div>

    <div class="second_row" :style="[mobilePortrait ? { width: '100%' } : { display: 'flex', flex: '1', flexDirection: 'row', flexWrap: 'wrap' }]">

      <div v-if="sectionsEmpty && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="new_section" :style="[edit ? {'border-color': '#099f45', color: '#099f45', cursor: 'pointer' } : {'border-color': '#787878', color: '#787878' }]"
      @click.stop="edit ? addSection(0) : null">
        <svg v-if="!edit" class="svg_one"></svg>
        <svg v-else class="svg_two"></svg>
        <div class="titles">
          <h3 class="tileTitle">Add Section</h3>
          <p class="tileSubtext">0 books</p>
        </div>
      </div>
      <div v-if="edit && sectionsEmpty && beforeLoad" class="no_content">
        You don’t have any sections<br/><br/>
        Please click the Add Section button to get started.
      </div>
      <div v-if="!edit && sectionsEmpty && beforeLoad" class="no_content">
        You don’t have any sections<br/><br/>
        To get started, make sure you have editor privileges.  Once you do, click on the menu icon in the upper left hand corner: <br/>
        <i></i>
       Once you’ve clicked that icon, you will see a button just under your name that says “Edit Mode”. Click it and you’ll have the ability to add sections.
      </div>

      <draggable
        v-model="sections"
        ghost-class="ghost-card"
        :style="{ width: '100%' }"
        class="menu_items_mobile"
        @change="saveSortOrder"
        :animation="200"
        handle=".dragger"
      >
        <transition-group>
          <div v-for="(item, index) in sections" :key="item.id"
            @click="item.divider != 1 ? $router.push(item.route) : null"
            :class="[{ 'coming_soon': item.coming_soon }, {'menu_item': item.divider != 1 }, { 'menu_divider': item.divider == 1 }, { 'draggable': edit }]">
            
            <div v-if="edit && item.divider != 1 && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="add_section" @click.stop="sectionAdd = index">ADD</div>
            
            <div v-if="((this.sections[index+1] !== undefined && this.sections[index+1].divider == 1 && edit) || (this.sections[index+1] === undefined && this.sections[index].divider != 1 && edit)) && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="add_section_last" @click.stop="sectionAdd = index + 1">ADD</div>
            
            <div v-if="item.divider == 1 && edit && index == sections.length - 1 && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="add_section_after_divider" @click.stop="sectionAdd = index + 1">ADD</div>

            <span v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || item.permissions?.includes('delete'))" class="del_sect" @click.stop="this.deleteSectionIndex = index; deleteSection(item);"></span>

            <div v-if="item.divider == 1 && edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" class="dragger"></div>

            <div v-if="item.divider != 1">
              <span class="coming_soon_indicator" v-if="item.coming_soon">coming soon</span>

              <transition name="side-slide">
                <div>
                  <span v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || item.permissions?.includes('delete'))" class="del_sect" @click.stop="this.deleteSectionIndex = index; deleteSection(item); editedToSave = 'sections'"></span>
                  <span v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || item.permissions?.includes('edit'))" @click.stop="sectionEdit = index; selectedIcon = item.img; sectionEditTitle = item.title; editedToSave = 'sectionEdit'" class="edit_sect"></span>
                  <!-- <span v-if="edit" @click.stop="sectionEdit = index; selectedIcon = item.img; sectionEditTitle = item.title; editedToSave = 'sections'" class="add_sect"></span> -->
                </div>
              </transition>

              <svg :style="[!item.coming_soon ? { backgroundImage: item.img } : { backgroundImage: item.img_disabled }]"></svg>
              <div class="titles">
                <h3 class="tileTitle">{{ item.title }}</h3>
                <p class="tileSubtext">{{ item.desc }}</p>
              </div>
              
              <transition name="top-slide">
                <div v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" class="dragger"></div>
              </transition>

            </div>
          </div>
        </transition-group>
      </draggable>

    </div>
    
    <div class="delete_anything" @click.stop v-if="deleteNotEmpty || deleteCurrent || imageWarn >= 0">
      <h3 v-if="deleteNotEmpty">Section Deletion Warning!</h3>
      <h3 v-if="deleteCurrent">Library Deletion Warning!</h3>
      <h3 v-if="imageWarn >= 0">Image Deletion Warning!</h3>
      <div class="trash_can"></div>
      <div v-if="deleteNotEmpty" class="warn functional">
        If you delete this sections, all books and pages that are contained within the library will be DELETED.
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div v-if="deleteCurrent" class="warn functional">
        If you delete this library, all sections, books and pages that are contained within the library will be DELETED.
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div v-if="imageWarn >= 0" class="warn functional">
        This image WILL BE DELETED!
        <br/><br/>
        If you are sure you want to delete, select the green button below.
      </div>
      <div class="buttons">
        <btn class="button" v-text="'delete section'" v-if="deleteNotEmpty" @click="deleteWithArticles(deleteSectionIndex); editedToSave = 'sections'"></btn>
        <btn class="button" v-text="'delete Library'" v-if="deleteCurrent" @click="deleteLib"></btn>
        <btn class="button" v-if="imageWarn >= 0" v-text="'delete image'" @click="deleteImage(imgEdit); editedToSave = 'images'; imageWarn = -1"></btn>
        <btn class="button secondary" v-text="'cancel'" @click="deleteNotEmpty = false; deleteCurrent = false; imageWarn = -1"></btn>
      </div>
    </div>

    <transition name="fade">
      <div class="context_shadow"
        v-if="qrModalActive || imgEdit >= 0 || sectionEdit >= 0 || sectionAdd >= 0 || deleteNotEmpty || deleteCurrent || imageToShow != '' ||  sectionEdit >= 0 || titleEdit || imageWarn >= 0"
        @click.stop="universalSave(editedToSave)">
      </div>
    </transition>

    <transition name="totop_modal">
      <div v-if="imgEdit >= 0" class="updown image_process" :style="[[width < 600 ? { paddingBottom: '60px' } : {}], [imageWarn >= 0 ? {zIndex: '1'}: {}]]">
        <span class="options_toggle" @click="universalSave(editedToSave)">{{ imgEdit ? 'IMAGE MENU' : 'BOOK EDIT' }}</span>
        <div class="tag">
          <transition v-if="this.libraryimages[imgEdit] !== ''">
            <div :style="{ width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1', aspectRatio: '4/3' }">
              <transition v-if="imageEditor">
                <imgx :src="this.libraryimages[imgEdit]" :imageEditor="true"
                      @editedImageUploadCompleted="editedImageUploadComplete"
                      @editedImageClosed="editedImageClose"></imgx>
              </transition>
              <transition v-else>
                <div v-if="this.libraryimages[imgEdit] !== ''"
                     :style="{ backgroundImage: 'url('+this.libraryimages[imgEdit]+')', height: '100%', width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1' }">
                  <div class="delete_img_button" @click="imageWarn = imgEdit;"></div>
                </div>
              </transition>
            </div>
          </transition>
          <transition v-else>
            <div v-if="this.libraryimages[imgEdit] !== ''"
                 :style="{ backgroundImage: this.libraryimages[imgEdit], height: '191px', width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1' }">
              <div class="delete_img_button" @click="imageWarn = imgEdit;"></div>
            </div>
<!--            <DropZoneImage v-else :index='0' :height="100" @drop.prevent="drop" @change="selectedFile"-->
<!--                           @uploadCompleted="uploadComplete" @uploadStarted="uploadStarted"/>-->
          </transition>
        </div>
        <div v-if="imgEdit >= 0 && !imageEditor">
          <div v-if="this.libraryimages[imgEdit] == ''" class="dropzone_alike"></div>
          <div class="action_button upload_img">
            <DropZoneImage :index='imgEdit' :height="100" @drop.prevent="drop" @change="selectedFile"
                           @uploadCompleted="uploadComplete" @uploadStarted="uploadStarted"/>
          </div>
          <div class="action_button paste_img" @click="pasteImageFromClipboard">Paste from Clipboard <span v-if="uploadingImage"></span></div>
          <div v-if="this.libraryimages[imgEdit] != ''" class="action_button edit_img" @click="this.tempEditArticleImg = this.libraryimages[imgEdit]; imageEditor = true;">Edit Image</div>

        </div>
        <div v-if="imageEditor" class="buttons">
          <btn class="secondary" v-text="'Cancel'" @click="imageEditor = false;"></btn>
          <btn v-text="'Done'" @click="this.$store.state.imageEditorSave = true;"></btn>
        </div>
        <div v-else class="button_updown" @click="universalSave(editedToSave)"><span>Done</span></div>
      </div>
    </transition>

    <transition name="totop_modal">
      <div v-if="titleEdit" class="updown" :style="[width < 600 ? { paddingBottom: '60px' } : {}]">

        <span class="options_toggle" @click="universalSave(editedToSave)">LIBRARY EDIT</span>

        <div class="tag">
          <p>Title</p>
          <input type="text" v-model="this.title">
        </div>

        <div class="tag">
          <p>Subtitle</p>
          <input type="text" v-model="this.subtitle">
        </div>

        <div class="button_updown" @click="universalSave(editedToSave)"><span>Done</span></div>
      </div>
    </transition>

    <transition name="totop_modal">
      <div v-if="sectionEdit >= 0" class="updown section_info" :style="[width < 600 ? {paddingBottom: '60px'} : {}]">

        <span class="options_toggle" @click="universalSave(editedToSave)">SECTION EDIT</span>

        <div class="tag">
          <p>Title</p>
          <input v-if="this.sections[sectionEdit].title == 'New Section'" type="text" v-model="editTitle" :placeholder="this.sections[sectionEdit].title">
          <input v-else type="text" v-model="this.sections[sectionEdit].title">
        </div>

        <div class="tag">
          <p>Icon</p>
          <div class="selected_icon" :style="{backgroundImage: selectedIcon}"></div>
          <div @click="iconsToChange = !iconsToChange" class="change_icon_button" v-text="iconsToChange ? 'Cancel' : 'Change'"></div>
        </div>

        <transition name="totop">
          <div v-if="iconsToChange">
            <div class="tag icons_type">
              <p>Category</p>
              <div>
                <ul :style="iconTypesOpen ? {boxShadow : '0px 2px 4px 0px #00000040'} : null">
                  <transition v-for="(type, index) in iconTypes" :key="index">
                    <li v-if="this.iconTypesOpen || type === currentIconType" @click="this.iconTypesOpen = !this.iconTypesOpen; chooseCategory(type,index)"> {{ type.replaceAll('_', ' ') }}
                    </li>
                  </transition>
                </ul>
                <span @click="this.iconTypesOpen = !this.iconTypesOpen;" :style="iconTypesOpen ? { transform: 'rotate(180deg)' } : null"></span>
              </div>
            </div>
            <div class="" v-for="(type, index) in iconTypes" :key="index">
              <div v-if="currentIconType === type" class="box_of_icons">
                <span class="icon_in_category" v-for="(icon, index) in iconList[type]" :key="index"
                      :style="[{ backgroundImage: 'url(../' + icon.fullPathUrl + ')' }]"
                      @click="selectIcon('url(../' + icon.fullPathUrl + ')')">
                </span>
              </div>
            </div>
          </div>
        </transition>

        <span class="modal_divider" v-if="!iconsToChange"></span>
        <div class="button_updown" @click="universalSave(editedToSave)"><span>Done</span></div>
        <!-- <div class="delete_updown" v-if="!iconsToChange" @click="deleteSectionIndex = sectionEdit; this.deleteSection(sections[sectionEdit]); sectionEdit = -1"><span></span>DELETE SECTION</div> -->
      </div>
    </transition>

    <transition name="totop_modal">
      <div v-if="sectionAdd >= 0" class="updown section_info" :style="[width < 600 ? { paddingBottom: '60px' } : {}]">

        <span class="options_toggle" @click="universalSave(editedToSave)">ADD</span>

        <div class="tag">
          <div class="add_section_context" @click="addSection(sectionAdd); editedToSave = 'sections'; sectionAdd = -1">Add Section</div>
        </div>

        <div class="tag">
          <div class="add_divider_context" @click="addDivider(sectionAdd); editedToSave = 'sections'; sectionAdd = -1">Add Divider</div>
        </div>

        <div class="button_updown" @click="sectionAdd = -1"><span>Done</span></div>
        <!-- <div class="delete_updown" v-if="!iconsToChange" @click="deleteSectionIndex = sectionEdit; this.deleteSection(sections[sectionEdit]); sectionEdit = -1"><span></span>DELETE SECTION</div> -->
        </div>
      </transition>

      <transition name="totop_modal">
        <div v-if="qrModalActive" class="updown qr_code" :style="[width < 600 ? {paddingBottom: '60px'} : {}]">
          <span class="options_toggle">QR CODE GENERATOR</span>
          <div class="qrcode_block" @click.stop>
            <div class="tag">
              <p>Preview</p>
              <div style="margin-left: auto; height: 150px; display: flex; align-items: center; padding-right: 12px; opacity: .5;">
                <qrcode-vue :value="currentURL + '/machine/' + this.$route.params.id" :size="125" render-as="svg" level="H" />
              </div>
              <div style="display: none;">
                <qrcode-vue :id="'qrcode-image-'+ this.$route.params.id" :value="currentURL + '/machine/' + this.$route.params.id" :size="qrcodeSize" render-as="canvas" level="H" />
              </div>
              <div style="display: none;">
                <div :id="'qrcode-pdf-'+ this.$route.params.id">
                  <div v-for="it in 2" :key="it" class="qr_to_print">
                    <div class="bopus_branding">The Book of Knowledge for Your Business</div>
                    <qrcode-vue :value="currentURL + '/machine/' + this.$route.params.id" :size="qrcodeSize" render-as="canvas" level="H" />
                    <h2>{{qrcodeName !== '' ? qrcodeName : this.title}}</h2>
                    <p>Mount Near Machine to Enable Library</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="tag">
              <p>Name</p>
              <input style="text-align: right; padding-right: 16px;" type="text" v-model="qrcodeName" :placeholder="this.title">
            </div>
            <div class="tag">
              <p>Image Size</p>
              <!-- <span class="qrcode_size_title"></span> -->
              <div style="display: flex; justify-content: space-between; flex-direction: row; flex: 1;">
                <div class="qrcode-params-inputs">
                  <span class="qrcode_title">Size</span>
                  <input class="qrcode_input" type="text" v-model="qrcodeSize">
                </div>
                <div class="qrcode-params-inputs">
                  <span class="qrcode_title">Frame size</span>
                  <input class="qrcode_input" type="text" v-model="qrcodeFrameSize">
                </div>
              </div>
            </div>
          </div>

          <div class="qr_button_set">
            <div v-if="saveQRTypeChoose" class="qr_cancel" @click="saveQRTypeChoose = false;">cancel</div>
            <div v-else class="qr_cancel" @click="qrModalActive = false">cancel</div>
            <div v-if="saveQRTypeChoose" class="qr_save" @click="saveQRCode(this.$route.params.id);">Image</div>
            <div v-else class="qr_save" @click="saveQRTypeChoose = true;">save</div>
            <div v-if="saveQRTypeChoose" class="qr_copy" @click="saveQRCode(this.$route.params.id,'pdf')">PDF</div>
            <div v-else :class="'qr_copy ' + {'qr_copied' : QRcopied}" @click="copyQRCode(this.$route.params.id)">{{QRcopied ? 'copied' : 'copy'}}</div>
          </div>
        </div>
      </transition>

  </main>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import {VueDraggableNext} from 'vue-draggable-next';
import btn from '../components/buttons/button.vue';
import breadcrumb from '../components/breadcrumbtree/breadcrumb.vue';
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import VueZoomer from 'vue-zoomer/src';
import imgx from '../components/imageeditor/image.vue';
import LoadingOverlay from '../components/preloaders/LoadingOverlay.vue';
import whiteOverlay from '../components/preloaders/whiteOverlay.vue';
import QrcodeVue from 'qrcode.vue';
import html2pdf from 'html2pdf.js';


const DropZoneImage = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_image_lib.vue'),
);

export default {
  name: 'machineView',
  components: {
    QrcodeVue,
    btn,
    breadcrumb,
    DropZoneImage,
    draggable: VueDraggableNext,
    Carousel,
    Slide,
    Navigation,
    VZoomer: VueZoomer.Zoomer,
    imgx,
    LoadingOverlay,
    whiteOverlay
  },
  inject: ['apiPath', 'imagesControl'],
  data() {
    return {
      uploadingImage: false,
      qrModalActive: false,
      qrcodeName: '',
      currentURL: window.location.origin,
      qrcodeSize: 275,
      qrcodeFrameSize: 20,
      saveQRTypeChoose: false,
      QRcopied: false,
      title: '',
      editTitle: '',
      uploading: [false, false, false, false],
      subtitle: '',
      permissions: [],
      listOfIcons: [
        { name: '1111', value: [] },
        { name: '2222', value: [] },
        { name: '3333', value: [] },
        { name: '4444', value: [] },
        { name: '5555', value: [] },
      ],
      showAllList: false,
      currentIconType: 'Default',
      iconTypes: ['Default'],
      iconTypesOpen: false,
      iconList: {
        Default: [
          {fullPathUrl: 'img/bopus/bopus_hat_only.svg'},
          {fullPathUrl: 'img/library/arrange.svg'},
          {fullPathUrl: 'img/library/maintanance.svg'},
          {fullPathUrl: 'img/library/fix.svg'},
          {fullPathUrl: 'img/library/book.svg'},
          {fullPathUrl: 'img/library/navigation.svg'},
          {fullPathUrl: 'img/library/sos_black.svg'},
        ]
      },
      deleteCurrent: false,
      libraryimages: [],
      tempEditArticleImg: '',
      imageToShow: '',
      sectionEdit: -1,
      sectionAdd: -1,
      sections: [],
      sectionEditTitle: '',
      ifNewSectionSelected: 'Select...',
      newSectionSelected: null,
      deleteNotEmpty: false,
      titleEdit: false,
      deleteSectionIndex: -1,
      selectedIcon: '',
      iconToHighlight: '',
      currentUrl: '',
      counter: 0,
      isActive: 0,
      iconsToChange: false,
      imgEdit: -1,
      imageEditor: false,
      imageWarn: -1,
      width: window.innerWidth,
      editedToSave: '',
      beforeLoad: false,
      sectionsClone: [],
    };
  },
  computed: {
    positionRight(){
      if (this.width <= 1920 && this.width >= 481) {
        return '80px';
      } else if (this.width <= 480){
        return '50px';
      } else {
        return '140px';
      }
    },
    paddingLeft() {
      if (this.width <= 1920 && this.width >= 481) {
        return '46px';
      } else if (this.width <= 480){
        return '30px';
      } else {
        return '80px';
      }
    },
    paddingRight() {
      if (this.width <= 1920 && this.width >= 481) {
        return '46px';
      } else if (this.width <= 480){
        return '30px';
      } else {
        return '80px';
      }
    },
    sectionsEmpty() {
      if (this.sections.some(section => section.divider == 0)) {
        return false;
      } else {
        return true;
      }
    },
    pagWidth() {
      if (window.innerWidth < 600) {
        return '10px';
      } else {
        return '20px';
      }
    },
    pagActiveWidth() {
      if (window.innerWidth < 600) {
        return '47px';
      } else {
        return '100px';
      }
    },
    mobilePortrait() {
      if (window.innerWidth < 600 && window.innerWidth < window.innerHeight) {
        return true;
      } else {
        return false;
      }
    },
    mobileLandscape() {
      if (window.innerHeight < 500 && window.innerHeight < window.innerWidth) {
        return true;
      } else {
        return false;
      }
    },
    edit() {
      return this.$store.state.edit;
    },
  },
  methods: {
    here() {
      console.log(this.$route.params, 'this.$route.params.id');
    },
    copyQRCode(id) {
      document.getElementById('qrcode-image-'+id).toBlob(blob => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]));
      this.QRcopied = true;
      setTimeout(() => this.QRcopied = false, 2000);
    },

    getImageWithFrame (canvasElement, frameSize, frameColor = '#ffffff') {
      if (canvasElement === undefined) {
        return false;
      }
      let newCanvas = document.createElement('canvas');
      let newContext = newCanvas.getContext('2d');
      newCanvas.width = canvasElement.width + frameSize * 2;
      newCanvas.height = canvasElement.height + frameSize * 2;
      newContext.fillStyle = frameColor;
      newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
      newContext.drawImage(canvasElement, frameSize, frameSize);

      return newCanvas.toDataURL("image/jpeg", 1.0);//.toDataURL("image/png").replace("image/png", "image/octet-stream");
    },
    saveQRCode(id, type = 'image') {
      if (type === 'pdf') {
        html2pdf(document.getElementById('qrcode-pdf-'+id));
        return;
      }

      let image = this.getImageWithFrame(document.getElementById('qrcode-image-'+id), this.qrcodeFrameSize/*, '#0a9f45'*/);
      if (!image) {
        console.log('no canvas');
        return;
      }
      let a = document.createElement('a');
      a.href = image;
      a.download = (this.qrcodeName !== '' ? this.qrcodeName : this.title) + '.jpeg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },


    chooseCategory(type,i) {
      this.iconTypes.unshift(this.iconTypes.splice(i, 1)[0]);
      this.currentIconType = type;
      console.log(this.currentIconType,'this.type')
    },
    async sendFetch(url, data = null) {
      let formData = new FormData();
      formData.append('token', this.$store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${this.apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    },
    drop() {

    },
    selectedFile() {

    },
    deleteLib() {
      this.sendFetch(
        'entity/location/delete',
        {
          "slocationid": this.$route.params.id,
        }
      ).then(() => {
        this.$router.push({ path: '/select' })
      })
    },
    universalSave(part) {
      switch (part) {
        case 'sectionEdit':
          if (this.editTitle != '') {
            this.sections[this.sectionEdit].title = this.editTitle;
          } else if (this.editTitle == '' && this.title != 'New Section') {
            this.sections[this.sectionEdit].title = this.sections[this.sectionEdit].title;
          } else {
            this.sections[this.sectionEdit].title = 'New Section';
          }
          this.sendFetch(
              'entity/section/edit',
              {
                "sectionid": this.sections[this.sectionEdit].id,
                "Name": this.sections[this.sectionEdit].title,
                "Icon": this.sections[this.sectionEdit].img,
              }
          )
          break;
        case 'pictures':

          break;
        case 'header':
          this.sendFetch(
              'entity/location/edit',
              {
                "slocationid": this.$route.params.id,
                "Name": this.title,
                "subTitle": this.subtitle
              }
          )
          break;
        default:
          console.log('wrong action');
      }
      this.imgEdit = -1;
      this.sectionEdit = -1;
      this.selectedIcon = '';
      this.deleteNotEmpty = false;
      // this.deleteOption = 1;
      this.imageToShow = '';
      this.sectionEdit = -1;
      this.sectionAdd = -1;
      this.titleEdit = false;
      this.iconsToChange = false;
      this.deleteCurrent = false;
      this.qrModalActive = false;
    },
    saveSortOrder() {
      let sectionsIds = [];
      this.sections.forEach(function (e) {
        sectionsIds.push(e.id);
      });
      if (sectionsIds.length > 0) {
        this.sendFetch(
            'entity/section/resort',
            {
              "ids": JSON.stringify(sectionsIds)
            }
        )
      }
    },
    addSection(it) {
      this.sections.splice(it, 0, { img: 'url(../img/bopus/bopus_hat_only.svg)', divider: 0, title: 'New Section', desc: '0 Books'});
      this.sectionEdit = -1;
      this.sendFetch(
          'entity/section/add',
          {
            "slocationid": this.$route.params.id,
            "Name": 'New Section',
            "Icon": 'url(../img/bopus/bopus_hat_only.svg)',
          }
      ).then((response) => {
        if (response) {
          this.sections[it].id = response.toString();
          this.sections[it].route = '/section/' + response.toString();
          this.saveSortOrder();
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
    addDivider(it) {
      this.sections.splice(it, 0, { img: 'url(../img/bopus/bopus_hat_only.svg)', divider: 1, desc: 'divider' });
      this.sectionEdit = -1;
      this.sendFetch(
          'entity/section/add',
          {
            "slocationid": this.$route.params.id,
            "Name": 'divider',
            "Icon": 'url(../img/bopus/bopus_hat_only.svg)',
            "divider": 1,
          }
      ).then((response) => {
        if (response) {
          this.sections[it].id = response.toString();
          this.sections[it].route = '/section/' + response.toString();
          this.saveSortOrder();
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
    slideToSlide(data) {
      this.isActive = data.slidingToIndex;
    },
    show(i) {
      if (this.$store.state.edit) {
        return;
      } else {
        this.imageToShow = this.libraryimages[i].replace("thumb", "public")
        // console.log(this.imageToShow)
      }
    },
    uploadStarted(path, index) {
      this.uploading[index] = true;
      let url = '';
      url = path;
      url = url.replace('upload.', '');
      url = url + "/section";
      this.currentUrl = url;
    },
    uploadComplete(index) {
      if (index >= 0) {
        this.tempEditArticleImg = this.libraryimages[index];
        this.libraryimages[index] = this.currentUrl;
        this.imgEdit = index;
        this.imageEditor = true;
      }
      this.saveImagesToDB();

      if (index == -1) {
        console.log("Upload Error");
      }
      if (index == -2) {
        console.log("Upload Aborted");
      }
      this.uploading[index] = false;
      this.$forceUpdate();
    },
    editedImageUploadComplete(newUrl) {
      if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
        this.tempEditArticleImg = this.libraryimages[this.imgEdit];
        this.libraryimages[this.imgEdit] = newUrl;
        this.saveImagesToDB();
        this.imageEditor = false;
      } else {
        console.log('Image is not uploaded');
      }
    },
    editedImageClose() {
      this.libraryimages[this.imgEdit] = this.tempEditArticleImg;
      this.imageEditor = false;
    },
    saveImagesToDB() {
      let dbImages = JSON.parse(JSON.stringify(this.libraryimages));
      for (let i = 0; i < dbImages.length; i++) {
        let tempImage = this.imagesControl.getLinkForDB('url(' + dbImages[i] + ')').slice(4).slice(0, -1);
        dbImages[i] = tempImage !== null ? tempImage : dbImages[i];
      }
      this.sendFetch(
          'entity/location/edit',
          {
            "slocationid": this.$route.params.id,
            "images": '{"images":' + JSON.stringify(dbImages) + '}'
          }
      );
    },
    deleteImage(index) {
      this.libraryimages[index] = "";
      this.tempEditArticleImg = '';
      this.saveImagesToDB();
    },
    async pasteImageFromClipboard() {
      let imageObj = await this.imagesControl.getImageFromClipboard();
      if (imageObj) {
        this.uploadingImage = true;
        this.uploadImageToCloudflare(imageObj.file).then((result) => {
          if (result) {
            //this.imagesControl.set(result, `url(${imageObj.url})`);
            this.tempEditArticleImg = this.libraryimages[this.imgEdit];
            this.libraryimages[this.imgEdit] = imageObj.url;
            this.imageEditor = true;
            this.uploadingImage = false;
            //this.saveImagesToDB();
          }
        });
      }
    },
    async uploadImageToCloudflare(file) {
      const formData = new FormData();
      formData.append('file', file);
      return new Promise((resolve, reject) => {
        this.getOneTimeUploadUrl().then((url) => {
          const request = new XMLHttpRequest();
          let link = url.replace('https://upload.', 'https://') + '/public';
          request.open('POST', url);
          request.onerror = () =>
              reject('oh no something went wrong!');
          request.ontimeout = () =>
              reject('oh no request timed out!');
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              resolve(link);
            } else {
              reject('oh no something went wrong!');
            }
          };
          request.send(formData);
        });
      });
    },
    async getOneTimeUploadUrl() {
      let response = await fetch(`${this.apiPath}cloudflareGetUploadLinkImage?token=${this.$store.state.usertoken}`);
      return await response.text();
    },
    getData() {
      fetch(`${this.apiPath}machineInfo?locationid=${this.$route.params.id}&token=${this.$store.state.usertoken}`, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          console.log(response,'response')
        if (response.error === undefined) {
          this.beforeLoad = true;
          this.title = response.title;
          this.subtitle = response.subtitle;
          this.permissions = response.permissions;
          let breadcrumbList = [["Home", "/", "1"]];
          for (let i = 0; i < response.breadcrumbs.length; i++) {
            breadcrumbList.push([response.breadcrumbs[i].Name, '/machine/' + response.breadcrumbs[i].slocationid, response.breadcrumbs[i].machinekb]) //future backend change
          }

          if (response.machineimages === undefined || response.machineimages.length === 0) { //future backend change
            this.libraryimages = ['', '', '', ''];
          } else {
            this.libraryimages = response.machineimages; //future backend change
            for (let i = 0; i < this.libraryimages.length; i++) {
              let tempImage = this.imagesControl.get(this.libraryimages[i]);
              this.libraryimages[i] = tempImage !== null ? tempImage.replace('url(', '').replace(')','') : this.libraryimages[i];
            }
          }
          this.$store.state.iconsList.then((icons)=> {
            let sectionsIcons = icons.img.folders.library.folders;
            for (const [key, value] of Object.entries(sectionsIcons)) {
              this.iconTypes.push(key);
              this.iconList[key] = value.files;
            }
            console.log(sectionsIcons);
          });
          this.sections = response.sections.itemsTwo; // [{ name: 'Asset#', value: 'W2332894OEA' }, { name: 'Name', value: 'TF Case Packer Green Series' }, { name: 'Location', value: 'USA, Washingtonm Richland, Plant 6, Line 1' }];
          //this.ifSectionsEmpty();
        } else {
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          if (response.error === '403') {
            window.location.href = "/403";
          }
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
    selectIcon(im) {
      this.sections[this.sectionEdit].img = im;
      this.selectedIcon = im;
    },
    deleteSection(section) {
      if (section.desc === '0 Books' || section.desc === 'divider') {
        let id = section.id.toString();
        this.deleteEmptySection();
        this.universalSave('sections');
        this.sendFetch(
            'entity/section/delete',
            {
              "sectionid": id
            }
        );
      } else {
        this.deleteNotEmpty = true;
      }
    },
    deleteEmptySection() {
      if (Array.isArray(this.sections)) {
        this.sections.splice(this.deleteSectionIndex, 1);
      } else {
        delete this.sections[this.deleteSectionIndex];
      }
      this.deleteSectionIndex = -1;
    },
    deleteWithArticles(i) {
      let id = this.sections[this.deleteSectionIndex].id.toString();
        this.sendFetch(
            'entity/section/delete',
            {
              "sectionid": id
            }
        );
        if (Array.isArray(this.sections)) {
          this.sections.splice(this.deleteSectionIndex, 1);
        } else {
          delete this.sections[this.deleteSectionIndex];
        }
        this.deleteNotEmpty = false;
        this.deleteSectionIndex = -1;
        this.pattern = '';
        return;
    },
  },
  mounted() {
    this.emitter.all.clear();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.qr_to_print{
  border: 1px solid #787878;
  margin: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-image: url(../../public/img/wave7.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 90px;
  position: relative;
  .bopus_branding{
    height: 40px;
    line-height: 42px;
    padding-left: 80px;
    background-image: url(../../public/img/bopus/bopus.svg);
    background-size: 69px;
    background-repeat: no-repeat;
    background-position: center left;
    margin-bottom: 20px;
  }
  canvas{
    margin: 0 auto;
    width: 275px !important;
    height: 275px !important;
  }
  h2{
    color: #444;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
  }
  p{
    font-size: 0.65rem;
    line-height: 0.8rem;
    position: absolute;
    bottom: 0px;
    left: 3px;
    margin: 0px;
    color: #787878;
  }
}
.Modal_slider{
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: calc(100% - 1300px);
  min-width: 404px;
  max-width: 700px;
  box-sizing: border-box;
  padding: 0 30px 30px 30px;
  background-color: #fff;
  color: #444;
  font-size: 1rem;
  z-index: 20;
  box-shadow: inset 0px 17px 8px -15px #0000000F,inset 0px -17px 8px -15px #0000000F;
  .modal_slider_header{
    height: 48px;
    line-height: 48px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: #444;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape) {
      margin: 0px;
    }
  }
  .modal_slider_header::before,.modal_slider_header::after{
    content: '';
    width: calc(50% - 140px);
    height: 5px;
    position: absolute;
    background-color: #2525251A;
    top: 22px;
    border-radius: 3px;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape) {
      width: calc(50% - 120px);
    }
  }
  .modal_slider_header::before{
    left: 15px;
  }
  .modal_slider_header::after{
    right: 15px;
  }
  .Modal__inner{
    height: 100%;
  }
  .button{
    width: 100%;
    height: 42px;
    line-height: 42px;
    margin-top: auto;
    position: relative;
  }
  .button::before{
    content: '';
    position: absolute;
    height: 3px;
    border-radius: 1.5px;
    width: 100%;
    top: -28px;
    left: 0px;
    right: 0px;
    background-color: #2525251A;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .menu_items_mobile{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .Modal_slider{
    min-width: auto;
    max-width: none;
    width: 100%;
    top: initial;
    bottom: 0px;
    right: 0px;
    border-radius: 16px 16px 0 0;
    padding: 0px 16px 16px 16px;
    .button{
      margin-top: 34px;
    }
  }
}
.add_section_context,.add_divider_context{
  font-size: 1rem;
  color: #787878;
  background-repeat: no-repeat;
  background-position: 0px center;
  padding-left: 44px;
  cursor: pointer;
  width: 100%;
  height: 42px;
  line-height: 42px;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    height: 38px;
    line-height: 38px;
  }
}
.add_section_context{
  background-image: url(../../public/img/library/add_section.svg);
}
.add_divider_context{
  background-image: url(../../public/img/library/add_divider.svg);
}
.dropzone {
  position: absolute;
  width: inherit;
  height: inherit;
  left: 0px;
  border-radius: 16px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  text-align: left;
  background-image: url(../../public/img/library/uploader.svg);
  background-position: center 20%;
  background-repeat: no-repeat;
  background-size: 27%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #787878;
  line-height: 16px;
  white-space: initial;
  padding-top: 55px;
  height: 100%;
  z-index: 0;
}
.dropzone::after {
  content: 'Select to upload image max size:  20MB';
  width: 244px;
  position: absolute;
  display: inline-block;
  left: 50%;
  margin-left: -122px;
  top: 65%;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}
.slider_side{
  width: calc(50% - 20px);
  margin-right: 20px;
  max-width: 740px;
  margin-top: -5px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   max-width: 1340px;
  // }
}
.buttons {
  margin-top: 22px;
  text-align: center;
  padding-top: 22px;
  position: relative;
  .button {
    max-width: 264px;
    width: calc(50% - 8px);
  }
  .button:first-child {
    margin-right: 16px;
  }
}

.image_process{
  .options_toggle::after,.options_toggle::before{
    width: 120px;
  }
  .buttons{
    margin-top: auto;
  }
  .buttons::before{
    content: '';
    position: absolute;
    height: 2px;
    border-radius: 1.5px;
    width: 100%;
    top: -11px;
    left: 0px;
    right: 0px;
    background-color: #2525251A;
  }
}
.section_info,.qr_code{
  bottom: 0px;
  .options_toggle::before,.options_toggle::after{
    width: 120px;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape){
      width: 90px;
    }
  }
  .current_category{
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 500;
    background-color: #7878780D;
    flex: 1;
    text-indent: 16px;
    position: relative;
    box-sizing: border-box;
    span{
      display: inline-block;
      height: 20px;
      width: 20px;
      background-image: url(../../public/img/library/arrow_down_gray.svg);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 10px;
      right: 16px;
      transition: all .2s;
    }
  }
  .list_of_categories{
    margin: 0px;
    overflow-y: scroll;
    max-height: 160px;
    padding-left: 0px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
    li{
      list-style-type: none;
      height: 40px;
    }
  }
}
.qr_code{
  bottom: 0px;
  .options_toggle{
    line-height: 48px;
  }
  .options_toggle::before,.options_toggle::after{
    width: 75px;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape){
      width: 85px;
    }
  }
}
.qrcode-params-inputs {
  position: relative;
  display: flex;
  width: calc(50% - 5px);
}
.qr_button_set{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  margin-top: auto;
  border-top: 2px solid #2525251A;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-top: 32px;
    margin-bottom: 32px;
  }
  >div{
    height: 45px;
    line-height: 45px;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }
  .qr_save,.qr_copy{
    background-color: #d9e9e2;
    color: #444;
    width: calc(50% - 8px);
    transition: all .2s ease-in-out;
  }
  .qr_copy{
    margin-left: auto;
  }
  .qr_copied{
   transform: scale(110%);
  }
  .qr_cancel{
    border: 1px solid;
    width: 100%;
    border-color: #787878;
    color: #787878;
    background-color: #fff;
    margin-bottom: 16px;
    &:hover{
      border-color: #006C3E;
      color: #006C3E;
      background-color: #fff;
    }
    &:active{
      border-color: #004D2C;
      background-color: #004D2C;
      color: #fff;
    }
  }
}
.qrcode_block {
  cursor: default;
  background-color: #fff;
  box-sizing: border-box;
  transition: bottom 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.qrcode_block .tag {
  display: flex;
  margin-top: 30px;
  position: relative;
  align-items: center;
  .qrcode_title{
    position: absolute;
    height: 21px;
    width: 100%;
    display: block;
    background-color: #BCBCBC;
    padding: 0px;
    border-radius: 8px 8px 0 0;
    color: #444;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
  }
  .qrcode_title::after{
    content: 'px';
    display: block;
    position: absolute;
    height: 42px;
    right: 8px;
    bottom: -49px;
  }
  .qrcode_input{
    height: 58px;
    width: calc(50% - 5px);//125px;
    max-width: 150px;
    padding-top: 21px;
    text-align: right;
    padding-right: 30px;
    color: #444;
  }
  input{
    font-size: 1rem;
    flex: 1;
    box-sizing: border-box;
    outline: none;
    height: 42px;
    color: #787878;
    background-color: rgba(120, 120, 120, 0.05);
    border: 0px;
    width: 100%;
    padding-left: 16px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    input,p{
      height: 38px;
    }
  }
}
.qrcode_block .tag:first-child {
  margin-top: 0px;
}
.qrcode_block .tag > p {
  width: 120px;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
  color: #787878;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pag{
  margin-top: -10px;
  width: 100%;
  height: 20px;
  text-align: center;
  z-index: 999;
  span{
    height: 20px;
    width: 20px;
    display: inline-block;
    border-radius: 10px;
    background-color: rgba(37, 37, 37, 0.1);
    margin: 6px;
    transition: all .3s;
  }
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   height: 40px;
  //   span{
  //     height: 40px;
  //     width: 40px;
  //     border-radius: 20px;
  //   }
  // }
}
.carousel__slide{
  .upload{
    background-color: #E6ECE9;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(195, 216, 207, 1)' stroke-width='8' stroke-dasharray='20px%2c 5px' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  }
  
  // div:not(.emptyimagem,.upload){
  //   background-color: rgba(37, 37, 37, 0.1);
  // }
  >div{
    background-color: #ECECEC;
    border-radius: 16px;
    aspect-ratio: 4 / 3;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    height: 100%;
    width: 100%;
    span{
      position: absolute;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      padding: 0 17px;
      text-align: center;
      border-radius: 10px;
      font-size: 1rem;
      color: #787878;
      background-color: #fff;
      transition: all .2s;
      box-shadow: 0px 0px 20px 1px #2525250A;
      // @media screen and (min-width: 27 01px) and (max-width: 3840px){
      //   padding: 0 17px;
      //   top: 35px;
      //   right: 100px;
      //   height: 80px;
      //   line-height: 80px;
      // }
      // DESKTOP
      // @media screen and (min-width: 1025px) and (max-width: 27 00px){
      @media screen and (min-width: 1025px){
        top: 22px;
        height: 50px;
        line-height: 50px;
        padding: 0 17px;
      }
      // LAPTOP
      @media screen and (min-width: 901px) and (max-width: 1024px){
        top: 20px;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
      }
      // TABLET
      @media screen and (min-width: 481px) and (max-width: 900px){
        top: 20px;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
      }
      // MOBILE
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        top: 10px;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
      }
    }
  }
  .picture_edit{
    cursor: pointer;
    height: 44px;
    width: 44px;
    border-radius: 5px;
    background-color: #fff !important;
    position: absolute;
    z-index: 2;
    background-image: url(../../public/img/library/edit.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    box-shadow: 0px 0px 20px 1px #2525250A;
    transition: all .2s;
    // BIG SCREEN
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   right: 35px;
    //   top: 35px;
    //   height: 80px;
    //   width: 80px;
    //   background-size: 60px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      top: 22px;
      right: 25px;
      height: 50px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      top: 20px;
      right: 23px;
      height: 40px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      top: 20px;
      right: 23px;
      height: 40px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      top: 10px;
      right: 16px;
      height: 24px;
      width: 24px;
      background-size: 20px;
    }
  }
}
// CAROUSEL SETTINGS -->
.show_image {
  position: fixed;
  z-index: 3;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  img {
    max-height: 90%;
    max-width: 90%;
    position: relative;
    transform: translateY(-60%);
  }
}
.viewer-toolbar {
  ul {
    li {
      height: 30px;
      margin-bottom: -3px;
      margin-top: -3px;
      width: 30px;
    }
  }
}
.limit {
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #787878;
  bottom: 15px;
  margin-left: 50%;
  left: -50px;
  width: 100px;
  height: 32px;
  line-height: 16px;
  margin-bottom: 0px;
}
.emptyimage {
  border-radius: 12px;
  text-align: center;
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  background-color: #E6ECE9 !important;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(195, 216, 207, 1)' stroke-width='8' stroke-dasharray='20px%2c 5px' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  p {
    position: absolute;
    color: #787878;
    font-weight: 600;
    font-size: 18px;
    top: calc(50% - 24px);
    left: 0px;
    right: 0px;
    text-align: center;
  }
}
main {
  .page_header {
    line-height: 2rem;
    margin-top: 60px;
    padding-right: 2.5rem;
    @media screen and (min-width: 801px){
      padding-right: 230px;
    }
    .qr_code_button{
      position: absolute;
      top: 0px;
      right: 0px;
      padding-right: 2.5rem;
      cursor: pointer;
      background-image: url(../../public/img/library/QRCode.svg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      font-weight: 400;
      font-size: 1rem;
      color: #444444;
      @media screen and (max-width: 800px){
        width: 2rem;
        height: 2rem;
        span{
          display: none;
        }
      }
    }
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   margin-top: 95px;
    // }
  }
  .page_subheader {
    height: 32px;
    line-height: 32px;
  }
}

.upload_section {
  .secondary {
    margin-top: 32px;
  }
  h2 {
    text-align: left;
  }
  label {
    text-align: left;
  }
  text-align: center;
}
.context_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}
.upload {
  input {
    visibility: hidden;
    position: absolute;
  }
}
.coming_soon {
  cursor: auto;
  >h3 {
    color: #bcbcbc !important;
  }
  >p {
    color: #bcbcbc !important;
  }
}
.coming_soon_indicator {
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: rgba(0, 108, 62, 0.15);
  color: #006C3E;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  width: 85px;
  box-sizing: border-box;
  text-align: center;
}
.library {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  .page_subheader {
    font-size: 1rem;
    color: #787878;
    width: 100%;
    top: -20px;
    margin: 6px 0 0 0;
  }
  .second_row {
    margin-top: 16px;
    padding-bottom: 32px;
    width: 100%;
    align-items: flex-start;
  }
}

.no_content{
  margin-top: 5px;
  margin-bottom: 0px;
  width: calc(64% - 15px);
  max-width: calc(100% - 270px);
  min-width: 250px;
}
.new_section{
  cursor: default;
  margin-top: 5px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  min-width: 170px;
  max-width: 250px;
  width: calc(33% - 10px);
  aspect-ratio: 16.3/10;
  border: 4px dashed;
  transition: all 0.2s ease-out;
  .titles{
    position: absolute;
    left: calc(33% + 10px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.2s ease-out;
    padding-right: 5px;
    width: 100%;
    max-width: calc(67% - 15px);
    h3{
      max-height: 54px;
      overflow: hidden;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 5px;
      margin-top: 0px;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    p{
      font-weight: 400;
      margin: 0px;
    }
  }
  svg {
    transition: all 0.2s ease-out;
    aspect-ratio: 1/1;
    max-width: 33%;
    width: 83.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    margin-bottom: 10px;
  }
  .svg_one{
    background-image: url(../../public/img/library/maintanance_gray2.svg);
  }
  .svg_two{
    background-image: url(../../public/img/library/maintanance_green.svg);
  }
}
.add_section,.add_section_last,.add_section_after_divider{
  position: absolute;
  transform: rotate(270deg);
  height: 34px;
  line-height: 34px;
  top: 40%;
  font-size: 1rem;
  color: #787878;
  background-image: url(../../public/img/section/add_line.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 35px;
  width: fit-content;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   background-size: 45px;
  //   padding-left: 45px;
  //   top: 45%;
  //   height: 60px;
  //   line-height: 60px;
  // }
}
.add_section{
  left: -57px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   left: -90px;
  // }
}
.add_section_last{
  right: -57px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   right: -90px;
  // }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    right: -48px;
    top: 36.6%;
  }
}
.add_section_after_divider{
  left: -52px;
  top: 113px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   left: -90px;
  //   top: 170px;
  // }
}
.menu_item{
  cursor: pointer;
  display: inline-block;
  background: #FFF;
  border-radius: 16px;
  flex: 0 0 48%;
  box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
  position: relative;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  min-width: 210px;
  max-width: 250px;
  width: calc(33% - 16px);
  aspect-ratio: 16.3/10;
  margin-right: 16px;
  margin-bottom: 14px;
  svg {
    transition: all 0.2s ease-out;
    aspect-ratio: 1/1;
    max-width: 33%;
    width: 83.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    margin-bottom: 10px;
  }
  .dragger {
    cursor: grab;
    position: absolute;
    background-image: url(../../public/img/library/dragger_green.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px;
    height: 40px;
    width: 140px;
    top: 0px;
    left: calc(50% - 70px);
  }
  .titles{
    position: absolute;
    left: calc(33% + 10px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .2s;
    color: #787878;
    padding-right: 5px;
    width: 100%;
    max-width: calc(67% - 15px);
    h3{
      max-height: 2.6rem;
      overflow: hidden;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 5px;
      margin-top: 0px;
      word-wrap: break-word;
    }
    p{
      font-weight: 400;
      margin: 0px;
    }
  }
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   max-width: 600px;
  //   margin-right: 30px;
  //   margin-bottom: 30px;
  //   svg{
  //     width: 50%;
  //     left: 8.3%;
  //   }
  //   .titles{
  //     left: 43%;
  //     max-width: calc(62% - 30px);
  //     h3{
  //       max-height: none;
  //     }
  //   }
  //   .dragger {
  //     height: 60px;
  //     background-size: 100px;
  //     @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //       height: 80px;
  //       background-size: 120px;
  //     }
  //   }
  // }
}
.del_sect,.edit_sect{
  display: inline-block;
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  top: 0px;
  padding-top: 10px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   height: 50px;
  //   width: 50px;
  //   background-size: 45px;
  //   top: 10px;
  // }
}
.del_sect{
  background-image: url(../../public/img/library/delete_green.svg);
  left: 5px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   left: 15px;
  // }
}
.edit_sect{
  background-image: url(../../public/img/library/edit_green.svg);
  right: 10px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   right: 25px;
  // }
}
.menu_item.draggable{
  width: calc(33% - 34px);
  margin-left: 34px;
  padding-top: 42px;
  margin-right: 0px;
  .titles{
    top: 40px;
    height: calc(100% - 40px);
  }
  svg{
    margin-bottom: 0px;
    transform: translateY(-25%);
  }
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   width: calc(33% - 60px);
  //   margin-left: 60px;
  // }
}

.menu_divider{
  width: 100%;
  margin: 0 auto;
  padding-top: 0px !important;
  border-bottom: 1px solid #2525251A;
  position: relative;
  margin-bottom: 25px;
  height: 0px;
  .dragger {
    cursor: grab;
    margin: 0 auto;
    height: 24px;
    width: 80%;
    background-size: 60px;
    background-image: url(../../public/img/book/dragger.svg);
    background-repeat: no-repeat;
    background-position: center;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   height: 80px;
    //   background-size: 120px;
    //   background-position: center top;
    // }
  }
  .del_sect{
    cursor: pointer;
    display: inline-block;
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    height: 35px;
    top: 0px;
    left: -35px;
    background-image: url(../../public/img/delete.svg);
    width: 35px;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   width: 50px;
    //   height: 50px;
    //   background-size: 45px;
    //   left: -52px;
    // }
  }
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   margin-bottom: 30px;
  // }
}
.menu_divider.draggable{
  height: 35px;
  width: calc(100% - 60px);
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   width: calc(100% - 120px);
  // }
}

.draggable {
  padding-top: 50px;
  // height: 205px;
}

.menu_item:hover {
  cursor: pointer;
}

.side-slide-enter-active,
.side-slide-leave-active,
.top-slide-enter-active,
.top-slide-leave-active,
.slide-updown-enter-active,
.slide-updown-leave-active,
.slide-updown2-enter-active,
.slide-updown2-leave-active
{
  transition: all 0.3s ease-out;
}

.side-slide-enter-from,
.side-slide-leave-to {
  margin-right: -40px;
}

.top-slide-enter-from,
.top-slide-leave-to {
  margin-top: -40px;
}

.slide-updown-enter-from,
.slide-updown-leave-to {
  margin-top: -50px;
}

.slide-updown2-enter-from,
.slide-updown2-leave-to {
  margin-bottom: 50px;
}

.picture-edit-enter-active,
.picture-edit-leave-active {
  transition: all 0.3s;
}
.picture-edit-leave-to,
.picture-edit-enter-from{
  opacity: 0;
}

@media screen and (max-width: 1024px) and (min-width: 540px) and (max-height: 1368px) and (min-height: 720px) and (orientation: portrait) {
  .carousel__slide{
    >div{
      aspect-ratio: 16 / 9;
    }
  }
  .add_section{
    left: -54px;
  }
  .add_section_last{
    right: -54px;
  }
  .slider_side{
    width: 100%;
    margin-right: 0;
    margin-top: -5px;
    max-width: none;
    margin-bottom: 24px;
  }
  .upload_section {
    left: auto;
    margin-left: auto;
    top: 10%;
  }
  .second_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    img {
      flex: 0 0 23%;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape) {
  .dropzone {
    height: 100%;
    width: 100%;
    background-image: url(../../public/img/library/uploader.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 45px;
    background-size: 30%;
  }
  .dropzone::after {
    width: 200px;
    margin-left: -100px;
    bottom: 15px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    bottom: 60px;
  }
  .pag{
    height: 20px;
    span{
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 5px;
      background-color: rgba(37, 37, 37, 0.1);
      margin: 3px;
      transition: all .3s;
    }
  }
  .vue-zoomer{
    height: auto;
    width: 90%;
    overflow: initial;
    // margin-top: 50%;
  }
  main {
    .page_header {
      margin-top: 50px;
      margin-bottom: 0px;
    }
    .page_subheader{
      margin-top: 0px;
    }
  }
  .upload_section {
    top: 20%;
    left: auto;
    margin-left: auto;
    padding: 16px;

    h2 {
      font-size: 18px;
    }

    .secondary {
      margin-top: 16px;
    }
  }

  .coming_soon_indicator {
    top: 18px;
  }

  .menu_item {
    height: 84px;
    flex: 0 0 100%;
    padding-top: 22px;
    padding-left: 20px;
    margin-right: 0px;
    position: relative;
    width: 100%;
    .dragger {
      background-position: center;
      height: 35px;
      width: auto;
      left: 35px;
      right: 35px;
    }
    .del_sect,.edit_sect{
      background-size: 20px;
      padding-top: 5px;
    }
    .del_sect{
      width: 35px;
      left: 0px;
    }
    .edit_sect{
      right: 5px;
      width: 25px;
    }
    svg {
      height: 40px;
      width: 40px;
      margin-bottom: 0px;
    }
    .titles{
      position: absolute;
      left: 78px;
      top: 8px;
      right: 0px;
      max-width: calc(100% - 55px);
      h3 {
        font-weight: 500;
        color: #787878;
        // max-height: 48px;
      }
      p {
        font-weight: 400;
      }
    }
  }
  .menu_divider{
    width: calc(100% - 68px);
    height: 8px !important;
    padding-top: 0px !important;
    margin-bottom: 16px;
    .dragger {
      margin: 0 auto;
      height: 24px;
      width: 80%;
      background-image: url(../../public/img/book/dragger.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .del_sect{
      display: inline-block;
      background-size: 25px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      height: 35px;
      top: -10px;
      left: -35px;
      background-image: url(../../public/img/delete.svg);
      width: 35px;
    }
  }
  .menu_divider.draggable{
    height: 24px !important;
    margin-bottom: 26px;
  }

  .draggable {
    padding-top: 50px;
    height: 105px;
  }

  .library {
    .second_row {
      margin-top: 16px;
      padding-bottom: 8px;
      .menu_item {
        margin-bottom: 14px;
        p {
          top: 50px;
        }
      }
    }
  }

  .dragger {
    background-position: center 10px;
  }
}

@media screen and (max-height: 920px) and (min-height: 600px) and (max-aspect-ratio:16/8) and (min-aspect-ratio:16/9){
  .slider_side{
    width: calc(40% - 100px);
  }
}
@media screen and (max-width: 599px) and (orientation: portrait), (max-height: 599px) and (orientation: landscape){
  .slider_side{
    width: 100%;
    margin-right: 0;
    margin-top: -5px;
    max-width: none;
  }
  .second_row{
    >div{
      // display: flex;
      // justify-content: space-between;
      // align-content: flex-start;
      // flex-wrap: wrap;
    }
  }
  .carousel__slide{
    .upload{
      background-color: #E6ECE9;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(195, 216, 207, 1)' stroke-width='8' stroke-dasharray='20px%2c 5px' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
  }
  .library{
    .page_subheader{
      height: 24px;
      line-height: 24px;
    }
    .second_row{
      .new_section{
        height: 90px;
        width: calc(50% - 5px);
        border-radius: 16px;
        padding-left: 5px;
        overflow: hidden;
        flex: 0 0 48%;
        min-width: auto;
        margin-bottom: 10px;
        .titles{
          left: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3{
            // max-height: 48px;
            overflow: hidden;
            margin-bottom: 0px;
          }
          p{
            font-weight: 400;
            margin: 0px;
          }
        }
      }
      .add_section{
        height: 30px;
        line-height: 30px;
        left: -50px;
        top: 40%;
        font-size: 1rem;
        color: #787878;
        background-image: url(../../public/img/section/add_line.svg);
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 35px;
      }
      .menu_item{
        height: 90px;
        border-radius: 16px;
        padding-left: 5px;
        padding-top: 25px;
        width: calc(50% - 8px);
        min-width: auto;
        flex: none;
        .titles{
          left: 52px;
          height: 72px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3{
            // max-height: 48px;
            overflow: hidden;
            margin-bottom: 0px;
          }
          p{
            font-weight: 400;
            margin: 0px;
          }
        }
      }
      .menu_item.draggable{
        margin-right: 0px;
        width: calc(50% - 30px);
        margin-left: 30px;
        height: 140px;
        padding-top: 42px;
        flex: 0 0 42%;
        .titles{
          height: calc(100% - 40px);
          top: 30px;
          padding-top: 0px;
          h3{
            max-height: none;
          }
        }
      }
    }
  }
}
@media screen and (max-height: 500px) and (orientation: landscape) {
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  box-sizing: border-box;
}
</style>
